<template>
  <div class="body-custom" v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">権限検索</b></h3>
        </div>
        <div>
          <ButtonSearchMobile></ButtonSearchMobile>
        </div>
      </div>
      <div class="card-body">
        <div id="search-form">
          <el-form
              ref="ruleForm"
              label-position="left"
              class="demo-ruleForm demo-form"
              @keyup.enter.native="onSearch"
          >
            <div class="row">
              <div class="col-md-8 col-sm-12 col-xs-6">
                <div class="row">
                  <div class="col-md-3 col-6">
                    <el-form-item label="権限名" prop="role_name">
                      <el-input
                      name="role_name"
                      autocomplete="on"
                      placeholder="権限名を入力"
                      type="text"
                      v-model="searchForm.role_name"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-3 col-6">
                    <el-form-item label="ステータス" prop="role_status">
                      <el-select
                          multiple
                          style="width: 100%"
                          v-model="searchForm.role_status"
                      >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                         </el-option>
                      </el-select>
                    </el-form-item>
                  </div>

                  <div class="col-12 text-right">
                    <div class="button-submit-mobile" style="padding-top: 0">
                      <el-button
                          @click.prevent="onSearch"
                          type="primary"
                      >{{ $t("search") }}</el-button
                      >
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-4 col-xs-6 d-flex flex-wrap">
                  <div class="el-form-item">
                      <label class="el-form-item__label">&nbsp; </label>
                      <div class="el-form-item__content">
                          <div class="el-input">
                              <button @click.prevent="onSearch" class="btn btn-primary mr-2">{{$t('search')}}</button>
                          </div>
                      </div>
                  </div>
                  <div class="el-form-item">
                      <label class="el-form-item__label">&nbsp; </label>
                      <div class="el-form-item__content">
                          <div class="el-input">
                              <button
                                  :disabled="listRole.total <= 0"
                                  @click.prevent="exportExcel" class="btn btn-primary mr-2">{{$t('export_csv')}}</button>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </el-form>
        </div>
        <div id="data-table-list">
          <div class="header-table-mobile">
            <button @click="exportExcel"
              type="button" class="btn btn-primary mr-2"
              :disabled="listRole.total <= 0"
              >{{$t('export_csv')}}</button>
          </div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="listRole.total"
                :total-record="parseInt(listRole.total)"
                :current-page="parseInt(listRole.current_page)"
                :page-size-default="parseInt(listRole.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
          <el-table
              v-if="listRole.data && listRole.total > 0"
              class="mb-5"
              :data="listRole.data"
              style="width: 100%"
              @sort-change="sortRole"
          >
            <el-table-column
                prop="role_name"
                class="column-table-2"
                label="権限名"
                sortable="false"
                min-width="180"
            >
            <template slot-scope="scope">
                <span v-if="hasPermissonCheck('kengen_flg')">
                  <router-link :to="{ name: 'edit-role', params: { role_id: scope.row.role_id }}">{{ scope.row.role_name }}</router-link>
                </span>
                <span v-else>
                  <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.role_name }}</a>
                </span>
              </template>
            </el-table-column>
              <el-table-column
                  prop="admin_user_count"
                  class="column-table"
                  label="利用数"
                  sortable="false"
                  min-width="100"
              >
                  <template slot-scope="scope">
                <span v-if="scope.row.role_status == constants.ROLE_STATUS[0].value">
                <router-link :to="{ name: 'list-account', query: { role_id: scope.row.role_id }}">
                   {{ scope.row.admin_user_count }}
                </router-link>
                 </span>
                      <span v-else>
                   {{ scope.row.admin_user_count }}
                </span>
                  </template>
              </el-table-column>
            <el-table-column
                prop="role_status"
                class="column-table"
                label="ステータス"
                sortable="false"
                min-width="150"
            >
              <template slot-scope="scope">
                <span v-if="parseInt(scope.row.role_status) === constants.ROLE_STATUS[0].value">{{constants.ROLE_STATUS[0].label}}</span>
                <span v-else-if="parseInt(scope.row.role_status) === constants.ROLE_STATUS[1].value"
                >{{constants.USER_STATUS[1].label}}</span
                >
              </template>
            </el-table-column>
              <el-table-column prop="kengen_flg" class="column-table" label="権限承認許可" min-width="100">
                  <template slot-scope="scope">
                      {{ convertPermission(scope.row.kengen_flg) }}
                  </template>
              </el-table-column>
              <el-table-column prop="form_flg" class="column-table" label="フォーム許可" min-width="100">
                  <template slot-scope="scope">
                      {{ convertPermission(scope.row.form_flg) }}
                  </template>
              </el-table-column>
              <el-table-column prop="answer_flg" class="column-table" label="回答閲覧許可" min-width="100">
                  <template slot-scope="scope">
                      {{ convertPermission(scope.row.answer_flg) }}
                  </template>
              </el-table-column>
              <el-table-column  prop="tennant_flg" class="column-table" label="テナント設定許可" min-width="150">
                  <template slot-scope="scope">
                      {{ convertPermission(scope.row.tennant_flg) }}
                  </template>
              </el-table-column>
              <el-table-column prop="mail_flg" class="column-table" label="メール設定許可" min-width="100">
                  <template slot-scope="scope">
                      {{ convertPermission(scope.row.mail_flg) }}
                  </template>
              </el-table-column>
            <el-table-column
                label="テナント名"
                min-width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.tenant">
                  <a target="_blank" :href="scope.row.tenant.admin_url">
                    {{scope.row.tenant.tenant_name}}
                  </a>
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div v-else class="text-center no-list-data">{{$t('el.table.emptyText')}}</div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="listRole.total"
                :total-record="parseInt(listRole.total)"
                :current-page="parseInt(listRole.current_page)"
                :page-size-default="parseInt(listRole.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>
import mixinSearch from "@/mixins/searchMixin";
import * as constants from "@/core/config/constant";
import { mapActions, mapGetters } from "vuex";
import {
  GET_LIST_ROLE,
  EXPORT_ROLE_FILE_CSV
} from "@/core/services/store/role.module";
import ButtonSearchMobile from "@/view/components/ButtonSearchMobile";

export default {
  name: "list-role",
  components: {ButtonSearchMobile},
  mixins: [mixinSearch],
  data() {
    return {
      searchForm: {
        role_name: "",
        role_status: [],
      },
      options: [],
      loading: false,
      constants: constants,
      not_permisson_popup : false
    };
  },
  computed: {
    ...mapGetters(["listRole"]),
    queryParam() {
      return this.$route.query
    },
  },
  watch: {
    async queryParam() {
      this.loading = true;
        if (Object.keys(this.queryParam).length === 0){
            this.resetDataSearch();
        }
      await this.getListRole(this.queryParam);
      this.getDataFromQuery();
      this.loading = false;
    },
  },
  async created() {
    if (this.hasPermissonCheck('kengen_flg')) {
      this.loading = true;
      this.getDataFromQuery();
      this.options = constants.ROLE_STATUS;
      await this.getListRole(this.queryParam);
      this.loading = false;
    } else {
      this.not_permisson_popup = true;
    }
  },
  methods: {
    ...mapActions([GET_LIST_ROLE, EXPORT_ROLE_FILE_CSV]),
    getDataFromQuery() {
      this.searchForm.role_name = this.queryParam.role_name ? this.queryParam.role_name : "";
      let arrayStatusRole = (this.queryParam.role_status && this.queryParam.role_status.length) ? this.queryParam.role_status.split(',') : [];
      this.searchForm.role_status = arrayStatusRole.map(item => parseInt(item));
    },
    onSearch() {
      this.getDataSearch(this.searchForm);
      this.search("list-role");
    },
    sortRole(sortProps) {
      this.sortColumn("list-role",sortProps);
      this.search("list-role");
    },
    handleSizeChange(val) {
      this.sizeChange("list-role", val);
    },
    handleCurrentChange(val) {
      this.pageChange("list-role", val);
    },
      async exportExcel() {
          if (this.hasPermissonCheck('kengen_flg')) {
              let data = await this.exportRoleFileCsv(this.queryParam);
              this.exportCsvCommon('SC018_', data)
          } else {
              this.not_permisson_popup = true
          }
      },
      convertPermission(premission) {
          if (premission == 1){
              return "許可";
          }else {
              return "不許可";
          }
      }
  }
};
</script>
<style scoped>
.ml-10 {
  margin-left: 10rem !important;
}

</style>
